import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { formatFullVPPayReturnCard, showData } from 'utils';

export function PaymentMethodChangeCard({ data }: { data?: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>{t('notify.change-card-successfully')}</p>
      <p className={styles.block}>
        {t('notify.your-card-number')}:{' '}
        <span className={styles.textBold}>
          {showData(formatFullVPPayReturnCard(data?.cardNumber))}
        </span>
      </p>
    </div>
  );
}
