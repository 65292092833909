import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { formatDate, formatFullVPPayReturnCard, formatPrice, isVN, showData } from 'utils';

export function SubscriptionPaymentT0Fail({ data }: { data?: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>
        {isVN() ? (
          <>
            Tài khoản của bạn{' '}
            <span className={styles.textBold}>
              {showData(formatFullVPPayReturnCard(data?.cardNumber))}
            </span>{' '}
            không đủ điều kiện thanh toán số tiền đến hạn là{' '}
            <span className={styles.textBold}>
              {formatPrice(Number(data?.contract?.totalFee), true)}
            </span>{' '}
            cho gói tập{' '}
            <span className={styles.textBold}>
              {showData(isVN() ? data?.paymentPlan?.nameVi : data?.paymentPlan?.nameEn)}
            </span>
            . Vui lòng cập nhật lại thẻ thanh toán hoặc liên hệ hotline{' '}
            <span className={styles.textBold}>1900 636920</span> để được hỗ trợ.
          </>
        ) : (
          <>
            We just tried to process your current amount due of{' '}
            <span className={styles.textBold}>
              {formatPrice(Number(data?.contract?.totalFee), true)}
            </span>{' '}
            for{' '}
            <span className={styles.textBold}>
              {showData(isVN() ? data?.paymentPlan?.nameVi : data?.paymentPlan?.nameEn)}
            </span>
            , but were unable to charge the credit card{' '}
            <span className={styles.textBold}>
              {showData(formatFullVPPayReturnCard(data?.cardNumber))}
            </span>
            . Please update your new card details or contact our hotline{' '}
            <span className={styles.textBold}>1900 636920</span> for assistance.
          </>
        )}
      </p>
      <p className={styles.block}>
        {t('notify.start-date')}:{' '}
        <span className={styles.textBold}>{formatDate(data?.contract?.startDate)}</span>
      </p>
      <p>
        {t('notify.end-date')}:{' '}
        <span className={styles.textBold}>{formatDate(data?.contract?.endDate)}</span>
      </p>
      <p>
        {t('notify.the-reason-declined-transaction')}{' '}
        <span className={styles.textBold}>{showData(data?.reason)}</span>
      </p>
      <p className={styles.block}>{t('notify.see-you-soon-at-tng')}</p>
    </div>
  );
}
