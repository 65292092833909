export enum NotificationActionType {
  MOBILE_MEMBERSHIP_REGISTRATION = 'MOBILE_MEMBERSHIP_REGISTRATION',
  MOBILE_MEMBERSHIP_PAYMENT = 'MOBILE_MEMBERSHIP_PAYMENT',
  MOBILE_MEMBERSHIP_REMINDER = 'MOBILE_MEMBERSHIP_REMINDER',
  MOBILE_MEMBERSHIP_FREEZE = 'MOBILE_MEMBERSHIP_FREEZE',
  MOBILE_UNFREEZE_FREEZE_CONTRACT = 'MOBILE_UNFREEZE_FREEZE_CONTRACT',
  MOBILE_PT_REGISTRATION = 'MOBILE_PT_REGISTRATION',
  MOBILE_PT_PAYMENT = 'MOBILE_PT_PAYMENT',
  MOBILE_PT_BOOKING = 'MOBILE_PT_BOOKING',
  MOBILE_PT_CANCELLATION = 'MOBILE_PT_CANCELLATION',
  MOBILE_PT_CANCELLATION_BY_USER = 'MOBILE_PT_CANCELLATION_BY_USER',
  MOBILE_PT_EXPERIENCE = 'MOBILE_PT_EXPERIENCE',
  MOBILE_CLASS_REGISTRATION = 'MOBILE_CLASS_REGISTRATION',
  MOBILE_CLASS_CANCELLATION = 'MOBILE_CLASS_CANCELLATION',
  MOBILE_PAYMENT_METHOD_LINK_CARD = 'MOBILE_PAYMENT_METHOD_LINK_CARD',
  MOBILE_PAYMENT_METHOD_CHANGE_CARD = 'MOBILE_PAYMENT_METHOD_CHANGE_CARD',
  MOBILE_PAYMENT_METHOD_DELETE_CARD = 'MOBILE_PAYMENT_METHOD_DELETE_CARD',
  MOBILE_PT_NOT_CONFIRM_BOOKING = 'MOBILE_PT_NOT_CONFIRM_BOOKING',
  MOBILE_EXPIRING_CONTRACT_AFTER_7_DAY = 'MOBILE_EXPIRING_CONTRACT_AFTER_7_DAY',
  MOBILE_EXPIRING_CONTRACT_TODAY = 'MOBILE_EXPIRING_CONTRACT_TODAY',
  MOBILE_RETURN_CONTRACT_T3_COUPON = 'MOBILE_RETURN_CONTRACT_T3_COUPON',
  MOBILE_RETURN_CONTRACT_T7_COUPON = 'MOBILE_RETURN_CONTRACT_T7_COUPON',
  MOBILE_RETURN_CONTRACT_T21_COUPON = 'MOBILE_RETURN_CONTRACT_T21_COUPON',

  MOBILE_SUBSCRIPTION_REGISTRATION = 'MOBILE_SUBSCRIPTION_REGISTRATION',
  MOBILE_SUBSCRIPTION_T2_PAYMENT_SUCCESS = 'MOBILE_SUBSCRIPTION_T2_PAYMENT_SUCCESS',
  MOBILE_SUBSCRIPTION_T2_PAYMENT_FAIL = 'MOBILE_SUBSCRIPTION_T2_PAYMENT_FAIL',
  MOBILE_SUBSCRIPTION_T0_PAYMENT_SUCCESS = 'MOBILE_SUBSCRIPTION_T0_PAYMENT_SUCCESS',
  MOBILE_SUBSCRIPTION_T0_PAYMENT_FAIL = 'MOBILE_SUBSCRIPTION_T0_PAYMENT_FAIL',
  MOBILE_SUBSCRIPTION_CANCELLATION_BY_SYSTEM = 'MOBILE_SUBSCRIPTION_CANCELLATION_BY_SYSTEM',
  MOBILE_SUBSCRIPTION_CANCELLATION_BY_USER = 'MOBILE_SUBSCRIPTION_CANCELLATION_BY_USER',
  MOBILE_SUBSCRIPTION_CHANGE_CARD = 'MOBILE_SUBSCRIPTION_CHANGE_CARD',
}
