export const ROUTES = {
  HOME: '/home',
  CHECK_IN: '/home/check-in',
  YOUR_PLAN: '/home/your-plan',
  REFERRAL: '/home/referral',
  WORKOUT_WITH_US: '/home/workout-with-us',
  TODAY_CLASSES: '/home/today-classes',

  HISTORY: '/history',
  HISTORY_MEMBERSHIP_DETAIL: '/history/membership/:contractId',
  HISTORY_PT_DETAIL: '/history/pt/:ptContractId',
  HISTORY_FREEZE_DETAIL: '/history/freeze/:freezeContractId',

  NOTIFICATION: '/notification',
  NOTIFICATION_DETAIL: '/notification/:notifyId',

  MEMBERSHIP: '/membership',
  MEMBERSHIP_CLUB_DETAIL: '/membership/:clubId/detail',
  MEMBERSHIP_SELECT: '/membership/:clubId',
  MEMBERSHIP_PAYMENT: '/membership/:clubId/plan/:paymentPlanId',
  MEMBERSHIP_PAYMENT_RESULT: '/membership/payment/result/:contractId',
  MEMBERSHIP_TRANSACTION_DETAIL: '/membership/transaction/:transactionId',
  MEMBERSHIP_FREEZE_PAYMENT: '/membership/freeze/payment',
  MEMBERSHIP_FREEZE_PAYMENT_RESULT: '/membership/freeze/payment/result/:freezeContractId',

  PT: '/pt',
  PT_SELECT: '/pt/select-pt',
  PT_PLAN: '/pt/select-pt/plans/:ptId',
  PT_PAYMENT_PLAN: '/pt/select-pt/plans/:ptId/plan/:contractId',
  PT_PAYMENT_RESULT: '/pt/payment/result/:ptContractId',
  PT_BOOKING: '/pt/booking',
  PT_CONTRACT: '/pt/contract',
  PT_CONTRACT_PREVIEW_PDF: '/pt/contract/:eContractId/preview',
  PT_CONTRACT_SIGN_PDF: '/pt/contract/:eContractId/preview/sign',

  CLASS: '/class',
  CLASS_DETAIL: '/class/:classId',

  TRAINING: '/training',
  TRAINING_PT_PLAN: '/training/plans/:ptId',
  TRAINING_PAYMENT_PT_PLAN: '/training/plans/:ptId/plan/:contractId',
  TRAINING_SCHEDULE: '/training/schedule',
  TRAINING_CONTRACT: '/training/contract',

  CLASSES: '/classes',

  ACCOUNT: '/account',
  ACCOUNT_TERM_CONDITION: '/account/terms-condition',
  ACCOUNT_CONTACT: '/account/contact',
  ACCOUNT_ABOUT: '/account/about',
  ACCOUNT_INFORMATION: '/account/information',
  ACCOUNT_VERIFICATION: '/account/verification',
  ACCOUNT_VERIFICATION_SUCCESS: '/account/verification/success',
  ACCOUNT_CHANGE_PHONE_NUMBER: '/account/information/change-phone',
  ACCOUNT_VERIFY_PHONE_NUMBER: '/account/information/change-phone/verify',
  ACCOUNT_PAYMENT_METHOD: '/account/information/payment-method',
  ACCOUNT_PAYMENT_METHOD_CARD_DETAIL: '/account/information/payment-method/:cardId',
  ACCOUNT_PAYMENT_METHOD_CARD_SUBSCRIPTION_DETAIL:
    '/account/information/payment-method/subscription-card',

  SUPPORT: '/support',
  SUPPORT_FACEBOOK_MESSENGER: '/support/facebook-messenger',
  SUPPORT_ZALO: '/support/zalo',
  SUPPORT_CALL_MEMBER_SERVICE: '/support/call-member-service',
  SUPPORT_REPORT: '/support/report',
  SUPPORT_REPORT_TYPE: '/support/report/:reportType',

  LOGIN: '/login',
  VERIFY_OTP: '/verify-otp',
  SIGN_UP: '/sign-up',
  LADING: '/landing',

  STATUS_PAYMENT_SUCCESS: '/status/payment-success',
  STATUS_PAYMENT_FAIL: '/status/payment-fail',
  STATUS_PAYMENT_POS: '/status/payment-at-pos',
  STATUS_PAYMENT_VNP: '/status/vnpay',
};
