import { render } from 'react-dom';
import App from './App';
import './styles/global.scss';
import './utils/i18n';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from 'stores';
import { initPWAConfig, isPWA, saveAppHeight } from 'utils';
import { InstallOverlay } from 'components';

const rootElement = document.getElementById('root');
if ('serviceWorker' in navigator) {
  let updated = false;
  let activated = false;
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      registration.addEventListener('updatefound', () => {
        const worker = registration.installing;
        worker?.addEventListener('statechange', () => {
          console.log({ state: worker.state });
          if (worker.state === 'activated') {
            // Here is when the activated state was triggered from the lifecycle of the service worker.
            // This will trigger on the first install and any updates.
            activated = true;
            if (activated && updated) {
              console.log('Application was updated refreshing the page...');
              window.location.reload();
            }
          }
        });
      });
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    // This will be triggered when the service worker is replaced with a new one.
    // We do not just reload the page right away, we want to make sure we are fully activated using the checkUpdate function.
    console.log({ state: 'updated' });
    updated = true;
    if (activated && updated) {
      console.log('Application was updated refreshing the page...');
      window.location.reload();
    }
  });
}
saveAppHeight();
initPWAConfig();
render(
  <Provider store={store}>
    <BrowserRouter>{isPWA() ? <App /> : <InstallOverlay />}</BrowserRouter>
  </Provider>,
  rootElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
