import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import CreditCard from 'components/card/CreditCard';
import { Button } from 'antd-mobile';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from 'components';
import { useDirection } from 'hooks';
import { ROUTES, VNPAY_SUBSCRIPTION_TMN_CODE, VNPAY_SUBSCRIPTION_URL } from 'constant';
import PaymentService from 'services/payment.service';
import { delayNavigate, formatFullVPPayReturnCard, isSuccessCode, isVN } from 'utils';
import { SavedCardType } from 'types/payment.type';
import { BANK_INFO_MAPPING } from 'constant/bank';
import { CARD_TYPE } from 'enum';
import LoadingDot from 'components/shared/LoadingDot';
import { UserService } from 'services';
import { SubscriptionType } from 'types';

export default function PaymentMethodContainer() {
  const paymentService = new PaymentService();
  const userService = new UserService();
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const [listCard, setListCard] = useState<SavedCardType[]>([]);
  const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionType>();
  const [isLoadingCard, setIsLoadingCard] = useState(true);

  const getListCard = async () => {
    const response = await paymentService.getExistCards();
    const { data, code } = response;
    if (isSuccessCode(code)) {
      setListCard(data);
    }
    delayNavigate(() => setIsLoadingCard(false));
  };

  const getRecurringCard = async () => {
    const response = await userService.getSubscriptionInfo();
    const { data, code } = response;
    if (isSuccessCode(code)) {
      setSubscriptionInfo(data);
    }
    delayNavigate(() => setIsLoadingCard(false));
  };

  const handleAddCard = async () => {
    const currentURl = [window.location.origin, window.location.pathname].join('');
    const response = await paymentService.addVNPayAddCardUrl({
      locale: isVN() ? 'vn' : 'en',
      cancelUrl: currentURl,
      cardType: CARD_TYPE.INTERNATIONAL,
      returnUrl: currentURl,
    });
    const { data, code } = response;
    if (isSuccessCode(code)) {
      window.location.href = data?.vnpayUrl;
    }
  };

  const handleChangeSubscriptionCard = async () => {
    const currentURl = [window.location.origin, window.location.pathname].join('');
    const subscriptionId = subscriptionInfo?.id;
    if (subscriptionId) {
      const response = await userService.changeSubscriptionCard({
        vnpaySubscriptionId: subscriptionId,
        locale: isVN() ? 'vn' : 'en',
        cancelUrl: currentURl,
        returnUrl: currentURl,
      });
      const { code, data } = response;
      if (isSuccessCode(code)) {
        const form = document.createElement('form');
        const ispTxnId = document.createElement('input');
        const tmnCode = document.createElement('input');
        const dataKey = document.createElement('input');
        ispTxnId.name = 'ispTxnId';
        ispTxnId.defaultValue = data?.transaction?.id;
        tmnCode.name = 'tmnCode';
        tmnCode.defaultValue = VNPAY_SUBSCRIPTION_TMN_CODE;
        dataKey.name = 'dataKey';
        dataKey.defaultValue = data?.dataKey;
        form.method = 'POST';
        form.action = VNPAY_SUBSCRIPTION_URL;
        form.appendChild(ispTxnId);
        form.appendChild(tmnCode);
        form.appendChild(dataKey);
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
      }
    }
  };

  useEffect(() => {
    getListCard();
    getRecurringCard();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperItem}>
        <p className="color-black">{t('title.default-bank-account')}</p>
        <div className={styles.listCard}>
          {isLoadingCard ? (
            <LoadingDot />
          ) : (
            listCard.map((card) => {
              return (
                <CreditCard
                  key={card.id}
                  isDefault={card?.isDefault}
                  cardIcon={BANK_INFO_MAPPING[card.bankCode]?.logo}
                  cardNumber={formatFullVPPayReturnCard(card.cardNumber)}
                  onClick={goTo(
                    ROUTES.ACCOUNT_PAYMENT_METHOD_CARD_DETAIL.replace(':cardId', String(card.id)),
                  )}
                />
              );
            })
          )}
        </div>
        <Button className={styles.addCard} onClick={handleAddCard}>
          <SvgIcon src="/images/icon/dashed-plus-icon.svg" />
          {t('button.add-new-card')}
        </Button>
      </div>
      {subscriptionInfo && (
        <div className={styles.wrapperItem}>
          <p className="color-black">{t('title.subscription-bank-account')}</p>
          <div className={styles.listCard}>
            <CreditCard
              isDefault={subscriptionInfo?.vnpayRecurringToken?.isDefault}
              cardIcon={BANK_INFO_MAPPING[subscriptionInfo?.vnpayRecurringToken?.bankCode]?.logo}
              cardNumber={formatFullVPPayReturnCard(
                subscriptionInfo?.vnpayRecurringToken?.cardNumber,
              )}
              onClick={goTo(ROUTES.ACCOUNT_PAYMENT_METHOD_CARD_SUBSCRIPTION_DETAIL)}
            />
          </div>
          <Button className={styles.addCard} onClick={handleChangeSubscriptionCard}>
            <SvgIcon src="/images/icon/switch-card.svg" width="18px" height="18px" />
            {t('button.change-card')}
          </Button>
        </div>
      )}
    </div>
  );
}
