export const NOTIFY_CONTENT = {
  // MEMBERSHIP
  MOBILE_MEMBERSHIP_REGISTRATION: {
    title: 'notify.membership',
    content: 'notify.register',
    icon: '/images/icon/star.svg',
  },
  MOBILE_MEMBERSHIP_PAYMENT: {
    title: 'notify.membership',
    content: 'notify.payment',
    icon: '/images/icon/star.svg',
  },
  MOBILE_MEMBERSHIP_REMINDER: {
    title: 'notify.membership',
    content: 'notify.reminder',
    icon: '/images/icon/star.svg',
  },
  MOBILE_MEMBERSHIP_FREEZE: {
    title: 'notify.membership',
    content: 'notify.freeze',
    icon: '/images/icon/star.svg',
  },
  MOBILE_UNFREEZE_FREEZE_CONTRACT: {
    title: 'notify.membership',
    content: 'notify.freeze',
    icon: '/images/icon/star.svg',
  },

  // PT
  MOBILE_PT_REGISTRATION: {
    title: 'notify.pt',
    content: 'notify.register',
    icon: '/images/icon/person-rotate.svg',
  },
  MOBILE_PT_PAYMENT: {
    title: 'notify.pt',
    content: 'notify.payment',
    icon: '/images/icon/person-rotate.svg',
  },
  MOBILE_PT_BOOKING: {
    title: 'notify.pt',
    content: 'notify.confirm-booking',
    icon: '/images/icon/person-rotate.svg',
  },
  MOBILE_PT_CANCELLATION: {
    title: 'notify.pt',
    content: 'notify.cancel-booking',
    icon: '/images/icon/person-rotate.svg',
  },
  MOBILE_PT_NOT_CONFIRM_BOOKING: {
    title: 'notify.pt',
    content: 'notify.cancel-booking',
    icon: '/images/icon/person-rotate.svg',
  },
  MOBILE_PT_CANCELLATION_BY_USER: {
    title: 'notify.pt',
    content: 'notify.cancel-booking',
    icon: '/images/icon/person-rotate.svg',
  },
  MOBILE_PT_EXPERIENCE: {
    title: 'notify.pt',
    content: 'notify.experience',
    icon: '/images/icon/person-rotate.svg',
  },

  // CLASS
  MOBILE_CLASS_REGISTRATION: {
    title: 'notify.class',
    content: 'notify.register',
    icon: '/images/icon/calendar.svg',
  },
  MOBILE_CLASS_CANCELLATION: {
    title: 'notify.class',
    content: 'notify.cancel-class',
    icon: '/images/icon/calendar.svg',
  },

  // PAYMENT METHOD
  MOBILE_PAYMENT_METHOD_LINK_CARD: {
    title: 'notify.payment-method',
    content: 'notify.link-card',
    icon: '/images/icon/payment-method.svg',
  },
  MOBILE_PAYMENT_METHOD_CHANGE_CARD: {
    title: 'notify.payment-method',
    content: 'notify.change-card',
    icon: '/images/icon/payment-method.svg',
  },
  MOBILE_PAYMENT_METHOD_DELETE_CARD: {
    title: 'notify.payment-method',
    content: 'notify.delete-card',
    icon: '/images/icon/payment-method.svg',
  },

  // MEMBERSHIP REMINDER
  MOBILE_EXPIRING_CONTRACT_AFTER_7_DAY: {
    title: 'notify.membership',
    content: 'notify.reminder',
    icon: '/images/icon/ring-clock.svg',
  },
  MOBILE_EXPIRING_CONTRACT_TODAY: {
    title: 'notify.membership',
    content: 'notify.reminder',
    icon: '/images/icon/ring-clock.svg',
  },

  // GIFT
  MOBILE_RETURN_CONTRACT_T3_COUPON: {
    title: 'notify.membership',
    content: 'notify.discount-limited',
    icon: '/images/icon/gift.svg',
  },
  MOBILE_RETURN_CONTRACT_T7_COUPON: {
    title: 'notify.membership',
    content: 'notify.discount-soon',
    icon: '/images/icon/gift.svg',
  },
  MOBILE_RETURN_CONTRACT_T21_COUPON: {
    title: 'notify.membership',
    content: 'notify.last-chance',
    icon: '/images/icon/gift.svg',
  },

  // SUBSCRIPTION
  MOBILE_SUBSCRIPTION_REGISTRATION: {
    title: 'notify.subscription',
    content: 'notify.register',
    icon: '/images/icon/subscription.svg',
  },
  MOBILE_SUBSCRIPTION_T2_PAYMENT_SUCCESS: {
    title: 'notify.subscription',
    content: 'notify.payment-successfully',
    icon: '/images/icon/subscription.svg',
  },
  MOBILE_SUBSCRIPTION_T2_PAYMENT_FAIL: {
    title: 'notify.subscription',
    content: 'notify.payment-fail',
    icon: '/images/icon/subscription.svg',
  },
  MOBILE_SUBSCRIPTION_T0_PAYMENT_SUCCESS: {
    title: 'notify.subscription',
    content: 'notify.payment-successfully',
    icon: '/images/icon/subscription.svg',
  },
  MOBILE_SUBSCRIPTION_T0_PAYMENT_FAIL: {
    title: 'notify.subscription',
    content: 'notify.payment-fail',
    icon: '/images/icon/subscription.svg',
  },
  MOBILE_SUBSCRIPTION_CANCELLATION_BY_SYSTEM: {
    title: 'notify.subscription',
    content: 'notify.subscription-cancel',
    icon: '/images/icon/subscription.svg',
  },
  MOBILE_SUBSCRIPTION_CANCELLATION_BY_USER: {
    title: 'notify.subscription',
    content: 'notify.subscription-cancel',
    icon: '/images/icon/subscription.svg',
  },
  MOBILE_SUBSCRIPTION_CHANGE_CARD: {
    title: 'notify.payment-method',
    content: 'notify.change-card',
    icon: '/images/icon/payment-method.svg',
  },
};
