import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import {
  formatDate,
  formatFullVPPayReturnCard,
  formatPrice,
  generateClubName,
  isVN,
  showData,
} from 'utils';

export function SubscriptionPaymentT2Success({ data }: { data?: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>{t('notify.your-membership-renew')}</p>
      <p className={styles.block}>
        - {t('notify.package-name')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(isVN() ? data?.paymentPlan?.nameVi : data?.paymentPlan?.nameEn)}
        </span>
      </p>
      <p>
        - {t('notify.club')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {generateClubName(isVN() ? data?.club?.nameVi : data?.club?.nameEn)}
        </span>
      </p>
      <p>
        - {t('notify.address')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(data?.club?.location)}
        </span>
      </p>
      <p className={styles.block}>
        {t('notify.start-date')}:{' '}
        <span className={styles.textBold}>{formatDate(data?.contract?.startDate)}</span>
      </p>
      <p>
        {t('notify.end-date')}:{' '}
        <span className={styles.textBold}>{formatDate(data?.contract?.endDate)}</span>
      </p>
      <p>
        {t('notify.total-payment')}:{' '}
        <span className={styles.textBold}>
          {formatPrice(Number(data?.contract?.totalFee), true)}
        </span>
      </p>
      <p>
        {t('notify.payment-method')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(isVN() ? data?.paymentMethod?.nameVi : data?.paymentMethod?.nameEn)}
        </span>
      </p>
      <p>
        {t('notify.your-registered-card')}:{' '}
        <span className={`${styles.textBold} ${styles.textUppercase}`}>
          {showData(formatFullVPPayReturnCard(data?.cardNumber))}
        </span>
      </p>

      <p className={styles.block}>{t('notify.pursue-your-fitness-goal')}</p>
    </div>
  );
}
