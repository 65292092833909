/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from './styles.module.scss';
import DetailCardInfo from 'components/card/DetailCardInfo';
import { DATE_FORMAT } from 'constant';
import { BANK_INFO_MAPPING } from 'constant/bank';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentService from 'services/payment.service';
import { SavedCardType } from 'types/payment.type';
import { delayNavigate, formatFullVPPayReturnCard, isSuccessCode } from 'utils';

type Props = {};

export default function CardSubscriptionDetailContainer({}: Props) {
  const paymentService = new PaymentService();
  const { t } = useTranslation();
  const [cardDetail, setCardDetail] = useState<SavedCardType>();
  const [isLoading, setIsLoading] = useState(true);

  const getRecurringCard = async () => {
    const response = await paymentService.getRecurringCard();
    const { data, code } = response;
    if (isSuccessCode(code)) {
      setCardDetail(data);
    }
    delayNavigate(() => setIsLoading(false));
  };

  useEffect(() => {
    getRecurringCard();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <DetailCardInfo
          isSkeleton={isLoading}
          isDefault
          isSubscription
          expiredDate={
            cardDetail && moment(cardDetail.tokenExpDate, 'YYYYMMDD').format(DATE_FORMAT)
          }
          iconCard={BANK_INFO_MAPPING[cardDetail?.bankCode as keyof typeof BANK_INFO_MAPPING]?.logo}
          cardNumber={formatFullVPPayReturnCard(cardDetail?.cardNumber || '')}
        />
      </div>
    </div>
  );
}
