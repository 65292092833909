import { NotificationActionType } from 'enum';
import React, { useMemo } from 'react';
import {
  ContractExpiredAfter7Day,
  ContractExpiredToday,
  DiscountExpiredSoon,
  DiscountLastChance,
  DiscountLimited,
  MembershipFreeze,
  MembershipPayment,
  MembershipRegistration,
  MembershipReminder,
  MembershipUnfreeze,
  PaymentMethodChangeCard,
  PaymentMethodDeleteCard,
  PaymentMethodLinkCard,
  PtCancelBooking,
  PtCancelBookingByUser,
  PtConfirmBooking,
  PtExperience,
  PtNotConfirmBooking,
  PtPayment,
  PtRegistration,
  SubscriptionCancelBySystem,
  SubscriptionChangeCard,
  SubscriptionPaymentT0Fail,
  SubscriptionPaymentT0Success,
  SubscriptionPaymentT2Fail,
  SubscriptionPaymentT2Success,
  SubscriptionRegistration,
} from './template';
import { SubscriptionCancelByUser } from './template/SubscriptionCancelByUser';

type Props = {
  action?: NotificationActionType;
  data?: any;
};

function NotifyContent({ action, data }: Props) {
  const notifyContent = useMemo(() => {
    switch (action) {
      // MEMBERSHIP
      case NotificationActionType.MOBILE_MEMBERSHIP_REGISTRATION:
        return <MembershipRegistration data={data} />;
      case NotificationActionType.MOBILE_MEMBERSHIP_PAYMENT:
        return <MembershipPayment data={data} />;
      case NotificationActionType.MOBILE_MEMBERSHIP_REMINDER:
        return <MembershipReminder data={data} />;
      case NotificationActionType.MOBILE_MEMBERSHIP_FREEZE:
        return <MembershipFreeze data={data} />;
      case NotificationActionType.MOBILE_UNFREEZE_FREEZE_CONTRACT:
        return <MembershipUnfreeze data={data} />;

      // PT
      case NotificationActionType.MOBILE_PT_REGISTRATION:
        return <PtRegistration data={data} />;
      case NotificationActionType.MOBILE_PT_PAYMENT:
        return <PtPayment data={data} />;
      case NotificationActionType.MOBILE_PT_BOOKING:
        return <PtConfirmBooking data={data} />;
      case NotificationActionType.MOBILE_PT_CANCELLATION:
        return <PtCancelBooking data={data} />;
      case NotificationActionType.MOBILE_PT_NOT_CONFIRM_BOOKING:
        return <PtNotConfirmBooking data={data} />;
      case NotificationActionType.MOBILE_PT_CANCELLATION_BY_USER:
        return <PtCancelBookingByUser data={data} />;
      case NotificationActionType.MOBILE_PT_EXPERIENCE:
        return <PtExperience data={data} />;

      //CLASS
      case NotificationActionType.MOBILE_CLASS_REGISTRATION:
        return <></>;
      case NotificationActionType.MOBILE_CLASS_CANCELLATION:
        return <></>;

      //PAYMENT METHOD
      case NotificationActionType.MOBILE_PAYMENT_METHOD_LINK_CARD:
        return <PaymentMethodLinkCard data={data} />;
      case NotificationActionType.MOBILE_PAYMENT_METHOD_CHANGE_CARD:
        return <PaymentMethodChangeCard data={data} />;
      case NotificationActionType.MOBILE_PAYMENT_METHOD_DELETE_CARD:
        return <PaymentMethodDeleteCard data={data} />;

      //MEMBERSHIP REMINDER
      case NotificationActionType.MOBILE_EXPIRING_CONTRACT_AFTER_7_DAY:
        return <ContractExpiredAfter7Day data={data} />;
      case NotificationActionType.MOBILE_EXPIRING_CONTRACT_TODAY:
        return <ContractExpiredToday data={data} />;

      //GIFT
      case NotificationActionType.MOBILE_RETURN_CONTRACT_T3_COUPON:
        return <DiscountLimited data={data} />;
      case NotificationActionType.MOBILE_RETURN_CONTRACT_T7_COUPON:
        return <DiscountExpiredSoon data={data} />;
      case NotificationActionType.MOBILE_RETURN_CONTRACT_T21_COUPON:
        return <DiscountLastChance data={data} />;

      //SUBSCRIPTION
      case NotificationActionType.MOBILE_SUBSCRIPTION_REGISTRATION:
        return <SubscriptionRegistration data={data} />;
      case NotificationActionType.MOBILE_SUBSCRIPTION_T2_PAYMENT_SUCCESS:
        return <SubscriptionPaymentT2Success data={data} />;
      case NotificationActionType.MOBILE_SUBSCRIPTION_T2_PAYMENT_FAIL:
        return <SubscriptionPaymentT2Fail data={data} />;
      case NotificationActionType.MOBILE_SUBSCRIPTION_T0_PAYMENT_SUCCESS:
        return <SubscriptionPaymentT0Success data={data} />;
      case NotificationActionType.MOBILE_SUBSCRIPTION_T0_PAYMENT_FAIL:
        return <SubscriptionPaymentT0Fail data={data} />;
      case NotificationActionType.MOBILE_SUBSCRIPTION_CANCELLATION_BY_SYSTEM:
        return <SubscriptionCancelBySystem data={data} />;
      case NotificationActionType.MOBILE_SUBSCRIPTION_CANCELLATION_BY_USER:
        return <SubscriptionCancelByUser data={data} />;
      case NotificationActionType.MOBILE_SUBSCRIPTION_CHANGE_CARD:
        return <SubscriptionChangeCard data={data} />;

      default:
        return <></>;
    }
  }, [action, data]);

  return notifyContent;
}

export default React.memo(NotifyContent);
