import { LANGUAGE } from 'enum';
import { getUserContractsAction, getUserProfileAction, getUserSetting } from './actions';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AccountSettingRes, APIResponse, UserProfile } from 'types';
import { ContractType } from 'types/user.type';
import { isSuccessCode, LocalStorage, STORAGE_KEY } from 'utils';

const Storage = new LocalStorage();

interface State {
  userProfile?: UserProfile;
  userContracts?: ContractType[];
  selectedContract?: ContractType;
  userSetting?: AccountSettingRes;
}

const initialState: State = {
  userProfile: {},
  userContracts: [],
  selectedContract: {},
  userSetting: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeSelectedContract: (state: State, action: PayloadAction<ContractType>) => {
      state.selectedContract = action.payload;
    },
    clearUserProfile: (state: State) => {
      state.userProfile = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserProfileAction.fulfilled, (state, action: PayloadAction<APIResponse>) => {
      if (isSuccessCode(action.payload.code)) {
        state.userProfile = action.payload.data;
      }
    });
    builder.addCase(
      getUserContractsAction.fulfilled,
      (state, action: PayloadAction<APIResponse>) => {
        const data = action.payload.data?.data as ContractType[];
        if (isSuccessCode(action.payload.code)) {
          state.userContracts = data;
          if (data?.length > 0) {
            state.selectedContract = data[0];
          }
        } else {
          state.selectedContract = {};
        }
      },
    );
    builder.addCase(getUserSetting.fulfilled, (state, action: PayloadAction<APIResponse>) => {
      if (isSuccessCode(action.payload.code)) {
        state.userSetting = action.payload.data;
        if (state.userSetting?.accountSettingValueEn?.lang === LANGUAGE.EN) {
          Storage.setStorageItem(STORAGE_KEY.LANGUAGE, LANGUAGE.EN);
        } else {
          Storage.setStorageItem(STORAGE_KEY.LANGUAGE, LANGUAGE.VN);
        }
      }
    });
  },
});

export const { changeSelectedContract, clearUserProfile } = userSlice.actions;
export default userSlice.reducer;
