import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { isVN, showData } from 'utils';

export function SubscriptionCancelByUser({ data }: { data?: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>
        {t('notify.confirm-you-cancel-subscription')}{' '}
        <span className={styles.textBold}>
          {showData(isVN() ? data?.paymentPlan?.nameVi : data?.paymentPlan?.nameEn)}
        </span>
        .
      </p>

      <p className={styles.block}>{t('notify.wish-rejoin')}</p>
      <p className={styles.block}>{t('notify.sorry-to-see-you-go')}</p>
    </div>
  );
}
