import React from 'react';
import styles from './styles.module.scss';
import SvgIcon from '../SVGIcon';
import { keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { E_PAYMENT_METHOD } from 'enum';

type Props = {};

const PAYMENT_METHOD = [
  {
    id: E_PAYMENT_METHOD.QR,
    name: 'internet-banking',
    iconPath: '/images/icon/qr-code.svg',
  },
  {
    id: E_PAYMENT_METHOD.CARD,
    name: 'credit-debit-card',
    iconPath: '/images/icon/debit-card.svg',
  },
  {
    id: E_PAYMENT_METHOD.CASH,
    name: 'cash',
    iconPath: '/images/icon/cash.svg',
  },
];

function PaymentMethodSubscriptionSelector({}: Props) {
  const { t } = useTranslation();
  const paymentMethodMapping = keyBy(PAYMENT_METHOD, 'id');

  return (
    <div className={styles.wrapper}>
      <div className={styles.selected}>
        <div className={styles.itemCard}>
          <SvgIcon
            pathFill={'var(--primary-color)'}
            src={paymentMethodMapping[E_PAYMENT_METHOD.CARD].iconPath}
          />
          <p className="color-blue font-md font-medium">
            {t(`title.${paymentMethodMapping[E_PAYMENT_METHOD.CARD].name}`)}
          </p>
        </div>
        <div className={styles.checkIcon}>
          <SvgIcon
            src="/images/icon/check.svg"
            pathFill="var(--white-text-color)"
            width="12px"
            height="10px"
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(PaymentMethodSubscriptionSelector);
