import { NavigateCard, SubscriptionInfoCard } from 'components';
import styles from './styles.module.scss';
import MoreInfoModal from 'components/modal/MoreInfoModal';
import { useTranslation } from 'react-i18next';
import { useDirection } from 'hooks';
import { PLAN_NAME_MAPPING, ROUTES } from 'constant';
import { useEffect, useState } from 'react';
import {
  delayNavigate,
  formatDate,
  formatPrice,
  generateClubName,
  isSuccessCode,
  isVN,
} from 'utils';
import { UserService } from 'services';
import { ContractType } from 'types';

type Props = {};

export function SubscriptionTab({}: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const userService = new UserService();
  const [contractInfo, setContractInfo] = useState<ContractType>();
  const [isLoading, setIsLoading] = useState(true);

  const getSubscriptionContractInfo = async () => {
    const response = await userService.getSubscriptionContract();
    const { data, code } = response;
    if (isSuccessCode(code)) {
      setContractInfo(data);
      console.log(data);
    }
    delayNavigate(() => setIsLoading(false));
  };

  useEffect(() => {
    getSubscriptionContractInfo();
  }, []);

  return (
    <div className={styles.wrapper}>
      <SubscriptionInfoCard
        isSkeleton={isLoading}
        planName={
          PLAN_NAME_MAPPING[contractInfo?.paymentPlan?.type as keyof typeof PLAN_NAME_MAPPING]
        }
        clubName={generateClubName(
          isVN() ? contractInfo?.club?.nameVi : contractInfo?.club?.nameEn,
        )}
        endDate={formatDate(contractInfo?.endDate)}
        nextBillingDate={formatDate(contractInfo?.nextBillingChargeDate)}
      />
      <div className={styles.controller}>
        <NavigateCard
          text={t('title.payment-method')}
          pathIcon="/images/icon/payment-method.svg"
          onClick={goTo(ROUTES.ACCOUNT_PAYMENT_METHOD)}
        />
        <MoreInfoModal
          text={t('title.cancellation-info')}
          modalTitle={t('title.cancellation-subscription-info')}
          modalContent={t('paragraph.cancellation-subscription', {
            joiningFee: formatPrice(Number(contractInfo?.paymentPlan?.joiningFee), true),
          })}
        />
      </div>
    </div>
  );
}
