import React from 'react';
import styles from './styles.module.scss';
import { showData } from 'utils';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd-mobile';

type Props = {
  planName?: string;
  clubName?: string;
  endDate?: string;
  nextBillingDate?: string;
  isSkeleton?: boolean;
};

function SubscriptionInfoCard({
  planName,
  clubName,
  endDate,
  nextBillingDate,
  isSkeleton = false,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.topInfo}>
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              width: '100%',
              height: '46px',
            }}
          />
        ) : (
          <p
            className="color-blue font-bebas font-medium"
            style={{
              fontSize: '36px',
            }}
          >
            {showData(planName)}
          </p>
        )}
        <p
          className="color-black font-xs"
          style={{
            fontStyle: 'italic',
          }}
        >
          {t('title.monthly-subscription')}
        </p>
      </div>
      <div className={styles.bottomInfo}>
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              width: '100%',
              height: '22px',
            }}
          />
        ) : (
          <p className="color-black font-md font-bold"> {showData(clubName)}</p>
        )}
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              width: '100%',
              height: '16px',
            }}
          />
        ) : (
          <p className="color-black font-xs font-medium">
            {t('title.end-date')}:{' '}
            <span className="color-black font-xs font-bold"> {showData(endDate)}</span>
          </p>
        )}
        {isSkeleton ? (
          <Skeleton
            animated
            style={{
              width: '100%',
              height: '16px',
            }}
          />
        ) : (
          <p className="color-black font-xs font-medium">
            {t('title.next-billing-date')}:{' '}
            <span className="color-black font-xs font-bold"> {showData(nextBillingDate)}</span>
          </p>
        )}
      </div>
    </div>
  );
}

export default React.memo(SubscriptionInfoCard);
