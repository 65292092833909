import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { formatFullVPPayReturnCard, isVN, showData } from 'utils';

export function SubscriptionCancelBySystem({ data }: { data?: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>
        {isVN() ? (
          <>
            Chúng tôi đã cố gắng gia hạn gói tập{' '}
            <span className={styles.textBold}>
              {showData(isVN() ? data?.paymentPlan?.nameVi : data?.paymentPlan?.nameEn)}
            </span>{' '}
            cho bạn 2 lần. Tuy nhiên, tài khoản của bạn{' '}
            <span className={styles.textBold}>
              {showData(formatFullVPPayReturnCard(data?.cardNumber))}
            </span>{' '}
            không thể thực hiện thành công giao dịch.
          </>
        ) : (
          <>
            We have made two attempts to charge your credit card for{' '}
            <span className={styles.textBold}>
              {showData(isVN() ? data?.paymentPlan?.nameVi : data?.paymentPlan?.nameEn)}
            </span>{' '}
            membership. Unfortunately, each of the attempts to charge the credit card{' '}
            <span className={styles.textBold}>
              {showData(formatFullVPPayReturnCard(data?.cardNumber))}
            </span>{' '}
            have been declined.
          </>
        )}
      </p>

      <p className={styles.block}>{t('notify.your-membership-cancel')}</p>
      <p className={styles.block}>{t('notify.extend-your-membership')}</p>
      <p className={styles.block}>{t('notify.sorry-to-see-you-go')}</p>
    </div>
  );
}
