import { TabWrapper } from 'components';
import styles from './styles.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalStorage, STORAGE_KEY, isSuccessCode, resetScroll } from 'utils';
import { MainPageTransition } from 'components/animation';
import PlansTab from './PlansTab';
import { FreezeTab } from './FreezeTab';
import { SubscriptionTab } from './SubscriptionTab';
import { UserService } from 'services';

type Props = {};

const TABS = {
  PLANS: 'PLANS',
  SUBSCRIPTION: 'SUBSCRIPTION',
  FREEZE: 'FREEZE',
};

const Storage = new LocalStorage();

export function MembershipContainer({}: Props) {
  const userService = new UserService();
  const previousTab = Storage.getStorageItem(STORAGE_KEY.MEMBERSHIP_TAB) || TABS.PLANS;
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string | number>(
    previousTab ? previousTab : TABS.PLANS,
  );
  const [subscriptionConfig, setSubscriptionConfig] = useState({
    isAllowSubscription: false,
    isFirstSubscription: false,
  });
  const [isLoading, setIsLoading] = useState(true);

  const checkSubscriptionContract = async () => {
    const response = await userService.checkSubscriptionContract();
    const { data, code } = response;
    if (isSuccessCode(code)) {
      setSubscriptionConfig(data);
    }
    setIsLoading(false);
  };

  const tabListRender = useMemo(() => {
    if (subscriptionConfig.isAllowSubscription) {
      if (previousTab === TABS.SUBSCRIPTION) {
        setSelectedTab(TABS.PLANS);
      } else {
        setSelectedTab(previousTab);
      }

      return [
        {
          key: TABS.PLANS,
          title: t('title.plans'),
        },
        {
          key: TABS.FREEZE,
          title: t('title.freeze'),
        },
      ];
    } else {
      setSelectedTab(previousTab);

      return [
        {
          key: TABS.PLANS,
          title: t('title.plans'),
        },
        {
          key: TABS.SUBSCRIPTION,
          title: t('title.subscription'),
        },
        {
          key: TABS.FREEZE,
          title: t('title.freeze'),
        },
      ];
    }
  }, [subscriptionConfig]);

  const handleChangeTab = (key: string | number) => {
    setSelectedTab(key);
    Storage.setStorageItem(STORAGE_KEY.MEMBERSHIP_TAB, key);
    resetScroll();
  };

  useEffect(() => {
    checkSubscriptionContract();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {!isLoading && (
          <TabWrapper activeKey={selectedTab} items={tabListRender} onChangeTab={handleChangeTab} />
        )}
      </div>
      <MainPageTransition>
        <div className={styles.body}>
          {selectedTab === TABS.PLANS && <PlansTab />}
          {selectedTab === TABS.SUBSCRIPTION && !subscriptionConfig.isAllowSubscription && (
            <SubscriptionTab />
          )}
          {selectedTab === TABS.FREEZE && <FreezeTab />}
        </div>
      </MainPageTransition>
    </div>
  );
}
