import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { formatDate, formatFullVPPayReturnCard, formatPrice, isVN, showData } from 'utils';

export function SubscriptionPaymentT2Fail({ data }: { data?: any }) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <p>
        {t('notify.hello')} <span className={styles.textBold}>{showData(data?.account?.name)}</span>
        ,
      </p>
      <p className={styles.block}>
        {isVN() ? (
          <>
            Tài khoản của bạn{' '}
            <span className={styles.textBold}>
              {showData(formatFullVPPayReturnCard(data?.cardNumber))}
            </span>{' '}
            không đủ điều kiện thanh toán số tiền đến hạn là{' '}
            <span className={styles.textBold}>
              {formatPrice(Number(data?.contract?.totalFee), true)}
            </span>{' '}
            cho gói tập{' '}
            <span className={styles.textBold}>
              {showData(isVN() ? data?.paymentPlan?.nameVi : data?.paymentPlan?.nameEn)}
            </span>
            . Vui lòng cập nhật lại thẻ thanh toán hoặc liên hệ hotline{' '}
            <span className={styles.textBold}>1900 636920</span> để được hỗ trợ.
          </>
        ) : (
          <>
            We just tried to process your current amount due of{' '}
            <span className={styles.textBold}>
              {formatPrice(Number(data?.contract?.totalFee), true)}
            </span>{' '}
            for{' '}
            <span className={styles.textBold}>
              {showData(isVN() ? data?.paymentPlan?.nameVi : data?.paymentPlan?.nameEn)}
            </span>
            , but were unable to charge the credit card{' '}
            <span className={styles.textBold}>
              {showData(formatFullVPPayReturnCard(data?.cardNumber))}
            </span>
            . Please update your new card details or contact our hotline{' '}
            <span className={styles.textBold}>1900 636920</span> for assistance.
          </>
        )}
      </p>
      <p className={styles.block}>
        {t('notify.start-date')}:{' '}
        <span className={styles.textBold}>{formatDate(data?.contract?.startDate)}</span>
      </p>
      <p>
        {t('notify.end-date')}:{' '}
        <span className={styles.textBold}>{formatDate(data?.contract?.endDate)}</span>
      </p>
      <p>
        {t('notify.the-reason-declined-transaction')}{' '}
        <span className={styles.textBold}>{showData(data?.reason)}</span>
      </p>

      <p className={styles.block}>
        {isVN() ? (
          <>
            Lưu ý: The New Gym sẽ thực hiện lại giao dịch thanh toán{' '}
            <span className={styles.textBold}>
              {formatPrice(Number(data?.contract?.totalFee), true)}
            </span>{' '}
            vào Ngày hết hạn. Nếu hệ thống không thực hiện giao dịch thành công, gói tập{' '}
            <span className={styles.textBold}>
              {showData(isVN() ? data?.paymentPlan?.nameVi : data?.paymentPlan?.nameEn)}
            </span>{' '}
            của bạn sẽ bị tạm ngưng hoạt động.
          </>
        ) : (
          <>
            Notice: We will attempt to retry the card on the Expiration Date. If we are unable to
            successfully charge the outstanding balance of{' '}
            <span className={styles.textBold}>
              {formatPrice(Number(data?.contract?.totalFee), true)}
            </span>
            , your{' '}
            <span className={styles.textBold}>
              {showData(isVN() ? data?.paymentPlan?.nameVi : data?.paymentPlan?.nameEn)}
            </span>{' '}
            membership will be cancelled.
          </>
        )}
      </p>

      <p className={styles.block}>
        {isVN() ? (
          <>
            Sau khi gói tập bị tạm nhưng hoạt động, nếu muốn gia hạn bạn phải đóng lại phí hội viên
            là{' '}
            <span className={styles.textBold}>
              {formatPrice(Number(data?.paymentPlan?.joiningFee), true)}
            </span>
            . 
          </>
        ) : (
          <>
            After cancellation, you will need to pay the Start-up fee of{' '}
            <span className={styles.textBold}>
              {formatPrice(Number(data?.paymentPlan?.joiningFee), true)}
            </span>{' '}
            when registering for a new membership package.
          </>
        )}
      </p>

      <p className={styles.block}>{t('notify.see-you-soon-at-tng')}</p>
    </div>
  );
}
